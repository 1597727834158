function resizeSetHeight() {

	//ID
	// document.getElementById("element").setAttribute("style","height:" + window.innerHeight + "px");
	
	// //Class, if there is only one element
	//   document.getElementsByClassName("element__wrapper")[0].setAttribute("style","height:" + window.innerHeight + "px");
	  document.querySelectorAll("intro")[0].setAttribute("style","height:" + window.innerHeight + "px");
	  document.querySelectorAll("bilder")[0].setAttribute("style","height:" + window.innerHeight + "px");
  
	//Class, if there are several elements
	//   var x = document.querySelectorAll('text');
	  var x = document.querySelectorAll('text-wrapper');
  
	  for(var i=0; i< x.length;i++){
		  x[i].setAttribute("style","height:" + window.innerHeight + "px");
	  }
  
  }






function introAnimation() {



	var introTimer;
	
	clearTimeout(introTimer);
	
	introTimer = setTimeout(function () {
		$('titel').css("display", "block");
	}, 1000);

	introTimer = setTimeout(function () {
		$('titel').css("display", "none");
		$('subtitle').css("display", "block");
	}, 2500);

	introTimer = setTimeout(function () {
		$('subtitle').css("display", "none");
		$('kuenstlerin').css("display", "block");
	}, 4000);

	introTimer = setTimeout(function () {
		$('kuenstlerin').css("display", "none");
		$('ort').css("display", "block");
	}, 5500);

	introTimer = setTimeout(function () {
		$('ort').css("display", "none");
		$('laufzeit').css("display", "block");
	}, 7000);

	introTimer = setTimeout(function () {
		$('laufzeit').css("display", "none");
	}, 8500);
}







function audioControlHoverAnimation() {


	$(".spur.audio").on("mouseenter", function(){

		if ($(this).hasClass("aktiviert")) {
			
			return;
			
		} else if ($(this).hasClass("deaktiviert")) {

			var thisSchalterNoHover = $(this).find("schalter-nohover")
			var thisSchalterHover = $(this).find("schalter-hover")
			var thisAltTextFirst = thisSchalterHover.find(".alt-first");
			var thisAltTextSecond = thisSchalterHover.find(".alt-second");
			var thisTimer;

			clearTimeout(thisTimer);

			thisAltTextFirst.show();
			thisAltTextSecond.hide();

			thisSchalterNoHover.removeClass("schalter-visible").addClass("schalter-hidden")
			thisSchalterHover.removeClass("schalter-hidden").addClass("schalter-visible")


			thisTimer = setTimeout(function () {
			
				thisAltTextFirst.hide();
				thisAltTextSecond.show();
				
			}, 600); 

			
			thisTimer = setTimeout(function () {
				
				thisSchalterHover.removeClass("schalter-visible").addClass("schalter-hidden")
				thisSchalterNoHover.removeClass("schalter-hidden").addClass("schalter-visible")

			}, 1200); 


		};


	});
}







function audioControls() {

	// $(".audio").trigger("play");
	// $(".audio").trigger("pause");


	$(".spur.audio").on("click", function(){

		var thisSchalterNoHover = $(this).find("schalter-nohover")
		var thisSchalterHover = $(this).find("schalter-hover")
		var playButton = $(this).find(".play");
		var pauseButton = $(this).find(".pause");
		var spurClicked = $(this).data("trigger");
		var spurPlayed = $("audio-files").find("[data-audiospur='" + spurClicked + "']")
		var textActivated = $("#wort").find("[data-text='" + spurClicked + "']")


		// console.log(textActivated)
						
		thisSchalterHover.removeClass("schalter-visible").addClass("schalter-hidden")
		thisSchalterNoHover.removeClass("schalter-hidden").addClass("schalter-visible")

		// console.log("spurClicked: " + spurClicked + "textActivated: " + textActivated)

		if (playButton.hasClass("visible")) {

			spurPlayed.trigger("play");

			textActivated.removeClass("currently-inactive").addClass("currently-active")
			
			playButton.addClass("hidden").removeClass("visible")
			pauseButton.addClass("visible").removeClass("hidden")
			
			$(this).addClass("aktiviert").removeClass("deaktiviert")

			if (textActivated.find(".reading-active").length) {

				var textScrollTop = textActivated.scrollTop()
				var readingOffsetTop = textActivated.find(".reading-active").offset().top
				var pTopStartPosition = textScrollTop + readingOffsetTop

				textActivated.stop().animate({
					scrollTop: pTopStartPosition - $(window).height() /2 + textActivated.find(".reading-active").height() /2
				}, 500, 'swing');

			}
			
		} else if (playButton.hasClass("hidden")) {
			
			spurPlayed.trigger("pause");
			
			textActivated.removeClass("currently-active").addClass("currently-inactive")
			
			pauseButton.addClass("hidden").removeClass("visible")
			playButton.addClass("visible").removeClass("hidden")

			$(this).addClass("deaktiviert").removeClass("aktiviert")
			
		}

	})

}




function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randomStartTime() {

		// $('.video__random').each(function() { 
		// 	var randomStartTime = getRandomInt(0, 340) ;
		// 	console.log(randomStartTime);
		// 	$(this).find(".wistia_embed").addClass("time=" + randomStartTime)
		// });
	
		if ($(window).width() > 800){		

			// $('.random').each(function(elem) {
			$('.random').on('loadedmetadata', function() {
				var randomStartTime = getRandomInt(0, 600) ;
				this.currentTime = randomStartTime;
			});

		}
	
}






function wortHighlightReading() {

		var audioPlayer1 = document.getElementById("geraeusche");
		var audioPlayer2 = document.getElementById("spokenword1");
		var audioPlayer3 = document.getElementById("spokenword2");
		var audioPlayer4 = document.getElementById("spokenword3");

		var text1 = document.getElementById("text__geraeusche");
		var text2 = document.getElementById("text__spokenword1");
		var text3 = document.getElementById("text__spokenword2");
		var text4 = document.getElementById("text__spokenword3");


		$("text-wrapper").each(function(){

			// var textWrapperScrollTop = 0
			// var startElementPosition = $(this).find(".start").offset().top
			// var initialScrollPosition = textWrapperScrollTop + startElementPosition
			var randomScrollPosition = getRandomInt(500, 2000)

			$(this).scrollTop(randomScrollPosition)

		})

		
		highlightWhileReading(audioPlayer1, subtitles__1, text1, ".reading-1", "reading-1")
		highlightWhileReading(audioPlayer2, subtitles__2, text2, ".reading-2", "reading-2")
		highlightWhileReading(audioPlayer3, subtitles__3, text3, ".reading-3", "reading-3")
		highlightWhileReading(audioPlayer4, subtitles__4, text4, ".reading-4", "reading-4")


	

		function highlightWhileReading(audioPlayer, subtitles, text, reading, readingNoDot) {

			// console.log("audioPlayer: " + audioPlayer)
			// console.log("subtitles: " + subtitles)
			// console.log("text: " + text)
			// console.log("reading: " + reading)

			// console.log("LOADED")
			

			let elementsArray = document.querySelectorAll(".audio");
			
			elementsArray.forEach(function(elem) {

				
				elem.addEventListener("timeupdate", function(e){
					
					// console.log("UPDATING")
					// console.log(audioPlayer.currentTime)


					subtitles.forEach(function(element, index, array){
					
						if ((audioPlayer.currentTime >= element.start && audioPlayer.currentTime <= element.end) && (!text.children[index].classList.contains(readingNoDot))) {
		
							// Highlight current text
		
							$(reading).removeClass("reading-active")
							$(reading).removeClass(readingNoDot)
							text.children[index].classList.add(readingNoDot)
							text.children[index].classList.add('reading-active')
		
		
							// Scroll to current text

							if ($(reading).length) {
								
								var textScrollTop = $(reading).closest('text-wrapper').scrollTop()
								var readingOffsetTop = $(reading).offset().top
								var pTopStartPosition = textScrollTop + readingOffsetTop
								
								$(reading).closest('text-wrapper').stop().animate({
									scrollTop: pTopStartPosition - $(window).height() /2 + $(reading).height() /2
								}, 500, 'swing');
								
							}
							
							// Pause current text on hover/scroll
		
								$(reading).closest('text-wrapper').on({
									mouseover: function () {
										// $(this).stop(true).animate({}, 0)
									},
									mouseleave: function () {
		
										if ($(reading).closest('text-wrapper').hasClass("currently-active")) {
											$(reading).closest('text-wrapper').stop().animate({
												scrollTop: pTopStartPosition - $(window).height() /2 + $(reading).height() /2
											}, 500, 'swing');
		
										}
										
									}
								});
						}
						
					});
					
				// };
				});
			});

			
		};
}









function flurDuplicateVideos() {
	
	let options = {
		root: null,
		rootMargin: "0px",
		threshold: 0
	};

	let flurVideos = document.querySelectorAll('.flur__video');

	let observer = new IntersectionObserver(handleIntersect, options);
	var clone;

	function handleIntersect(entries, self) {

		entries.forEach((entry) => {
			
			if ((entry.isIntersecting) && ($(window).width() > 800)) {

				$(".flur__video.newest").removeClass("newest")
				
				let clone = entry.target.cloneNode(true)
				
				document.querySelector('#flur').appendChild(clone);
				clone.classList.remove("video__notrandom")
				clone.classList.add('video__random')
				
				entry.target.classList.add("in-viewport", "newest");

				// var randomStartTime = getRandomInt(0, 340);
				
				// var embedFrame = clone.querySelector('.wistia_embed')
				// embedFrame.classList.add("time=" + randomStartTime )


				observer.observe(clone);
				self.unobserve(entry.target);

			}

		  });
		
	  }

	  flurVideos.forEach(function(flurVideo) {
		observer.observe(flurVideo);
	  });





}







function raumNav() {

	$(".raumnav").click(function(){

		var raumClicked = $(this).data("raumnav")
		var raumObject = $("body").find("[data-raum='" + raumClicked + "']");
		var slidingTimer;

		if ($(this).hasClass("navselected")) {
			
			return;	

		} else {

			$(".navselected").removeClass("navselected");
			$(".slidingin").removeClass("navselected slidingin").addClass("slidingout");
			$(this).addClass("navselected");
			$(".raum").addClass("overflowhidden");
			raumObject.removeClass("beforesliding overflowhidden").addClass("slidingin");

			clearTimeout(slidingTimer);

			slidingTimer = setTimeout(function () {
				$(".slidingout").removeClass("slidingout").addClass("beforesliding");
			}, 400);

		}

		if (raumClicked !== "bild") {
			runningInterval = false;
			stopSlideshow();
		}

		if (raumClicked == "bild") {
			startSlideshow();
			$("#flur").removeClass("overflowhidden");
		}

		if (raumClicked == "funk") {
			// $("#flur").removeClass("overflowhidden");
		}

	})
}





function wortDuplicateText() {
	
	let options = {
		root: null,
		rootMargin: "0px",
		threshold: 0
	};

	let texts = document.querySelectorAll('text');

	let observer = new IntersectionObserver(handleIntersect, options);
	var clone;

	function handleIntersect(entries, self) {

		entries.forEach((entry) => {

			if (entry.isIntersecting) {
				
				// let clone = entry.target.cloneNode(true)
				let parent = entry.target.closest("text-wrapper")
				var closestCloneBase = parent.querySelector(".clone");
				let clone = closestCloneBase.cloneNode(true)

				parent.appendChild(clone);

				observer.observe(clone);
				self.unobserve(entry.target);

			}

		  });
		
	  }

	  texts.forEach(function(text) {
		observer.observe(text);
	  });





}







var slideInterval;

function startSlideshow() {

	clearInterval(slideInterval);
	clearTimeout(resumeInterval);

	slideInterval = setInterval(function() {

		$('bild-element:first').removeClass("current-slide").addClass("slide") //Hide current image
		$('bild-element:nth-child(2)').removeClass("slide").addClass("current-slide")  //Set second image in line to "visible"
		$('bild-element:first').appendTo('bilder').end()
		
	}, 7000); //Set autoplay interval

}

function stopSlideshow() {
	clearInterval(slideInterval);
	clearTimeout(resumeInterval);
}









function imgDescription() {
			
	$("bild-element img").each(function() {
		$(this).parent('bild-element').append($("<span/>").text($(this).attr("alt")));
	});
	
}






var resumeInterval;

function slideshowNav() {
	
	$("prev").on("click", function() {

		//Pause Autoplay

		clearInterval(slideInterval);


		//Change image order

		$('bild-element:first').removeClass("current-slide").addClass("slide")
		$('bild-element:last').removeClass("slide").addClass("current-slide")
		$('.current-slide').prependTo('bilder').end()
		

		//Resume autoplay

		clearTimeout(resumeInterval);

		resumeInterval = setTimeout(function () {

			startSlideshow()

		}, 7000);
		
	})
	
	$("next").on("click", function() {

		//Pause Autoplay

		clearInterval(slideInterval);


		//Change image order

		$('bild-element:first').removeClass("current-slide").addClass("slide")
		$('bild-element:nth-child(2)').removeClass("slide").addClass("current-slide")
		$('bild-element:first').appendTo('bilder').end()


		//Resume autoplay

		clearTimeout(resumeInterval);

		resumeInterval = setTimeout(function () {

			startSlideshow()

		}, 7000);
	})

}






function clickFunkzeichen() {

	$(document).on({
		mousemove: function (e) {
		  $("funkzeichen").css({
			left: e.pageX - 25,
			top: e.pageY -25
		  });
		},
		mousedown: function () {
		  $("funkzeichen").css({"display":"block", "cursor":"none"});
		  $("body").css({"cursor":"none"});

		},
		mouseup: function () {
		  $("funkzeichen").css({"display":"none", "cursor":"default"});
		  $("body").css({"cursor":"default"});
		}
	});

}



function displayFunkzeichen() {

	setInterval( function() {
		var currentdate = new Date(); 
		var datetime = + currentdate.getDate() + "/"
		+ (currentdate.getMonth()+1)  + "/" 
		+ currentdate.getFullYear() + ", "  
		+ currentdate.getHours() + ":"  
		+ currentdate.getMinutes() + ":" 
		+ currentdate.getSeconds();
		$("session.current>timestamp").html(datetime);
	},1000);

	
	var mousedown = false;
	var mousedown_timer = '';
	var neuesSignal = "";
	
	$(window).mousedown(function(e) {
		mousedown = true;
		neuesSignal = '<signal class="kurz"></signal>'

		mousedown_timer = setTimeout(function() {
			if(mousedown) {
				console.log('long');
				neuesSignal = '<signal class="lang"></signal>'
			} 	
			
		}, 200);
		
	}).mouseup(function(e) {

		mousedown = false;
		clearTimeout(mousedown_timer);
		$("session.current>funksignal").append(neuesSignal);
		neuesSignal= "";
	
	});
}